.authentication-form {
    background-color: #f8f9fa;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.authentication-form .card-title {
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
}

.authentication-form .form-control {
    border-radius: 5px;
}

.authentication-form .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

.authentication-form .btn-primary:hover {
    background-color: #0069d9;
    border-color: #0062cc;
}

.authentication-form .input-group {
    display: flex;
    align-items: center;
}

.authentication-form .input-group .form-control {
    flex: 1;
    margin-right: 10px;
}